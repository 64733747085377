const URL = 'https://api.everhour.com';
const API_KEY = '2894-ef99-5d1e2a-f7d699-845b15d2';
const USER_ID = '999019';
const CURRENT_TIMER = '/timers/current';

const headers = {
    'X-Api-Key': API_KEY,
    'Content-Type': 'application/json',
}

export const getCurrentTimer = async () => {
  const response = await fetch(`${URL}${CURRENT_TIMER}`, {
    headers,
  });
  return await response.json();
};

export const getUserTimeRecords = async (today) => {
  const response = await fetch(`${URL}/users/${USER_ID}/time?from=${today}&to=${today}&limit=20`, {
    headers,
  });

  return await response.json();
};
