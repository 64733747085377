import './App.css';
import { useEffect, useMemo, useState } from 'react';
import { getCurrentTimer, getUserTimeRecords } from './api';

const CURRENT_RATE = 12;
const INTERVAL = 2 * 1000;

const sumAllTimeRecords = (userTimeRecords) =>
  userTimeRecords.reduce((acc, curr) => acc + curr.time, 0);

function App() {
  const [seconds, setSeconds] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    fetchData(); // Initial API call
    const interval = setInterval(fetchData, INTERVAL); // Call API every 2 seconds

    return () => {
      clearInterval(interval); // Clear the interval when the component is unmounted
    };
  }, []); // Empty dependency array to run only once on mount

  const fetchData = async () => {
    try {
      const currentTimer = await getCurrentTimer();

      const today = new Date().toISOString().split('T')[0]; // Gets the current date in 'YYYY-MM-DD' format
      const userTimeRecords = await getUserTimeRecords(today);
      const sumTimeRecords = sumAllTimeRecords(userTimeRecords);

      if (currentTimer.status === 'stopped') {
        const userTimeRecords = await getUserTimeRecords(today);
        const sumTimeRecords = sumAllTimeRecords(userTimeRecords);
        setSeconds(sumTimeRecords);
        setIsTimerActive(false);
        return;
      }

      if (!isTimerActive) {
        setIsTimerActive(true);
      }
      const time = currentTimer.duration + sumTimeRecords;
      setSeconds(time);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const moneyEarned = useMemo(() => {
    return ((seconds / 60 / 60) * CURRENT_RATE ?? 0).toFixed(2) + '€';
  }, [seconds]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>{moneyEarned}</h1>
        <span>Timer is {isTimerActive ? 'active' : 'inactive'}</span>
      </header>
    </div>
  );
}

export default App;
